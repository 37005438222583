import { PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { getAccountDataAPI } from '../../api/account/getAccountDataAPI';
import { createLoginAPI } from '../../api/user/createLoginAPI';
import { LanguageLocale } from '../../constants/locales';
import { IAccountMeOutput } from '../../model/account';
import { handleApiError } from '../../utils/errorHandlingUtils';
import { setAccountState, setAccountStateFailure } from '../reducers/accountSlice';
import { addAlert } from '../reducers/alertSlice';
import { setAuthState } from '../reducers/authSlice';
import { fetchAllDictionaryData } from '../reducers/countrySlice';
import {
    getLoginCredentials,
    IGetLoginCredentials, setLoginFailure
} from '../reducers/loginSlice';
import { changeActiveLanguage } from '../reducers/sagaSlice';
import { ILoginInput } from '../../model/user';

const loginStart: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(getLoginCredentials.type),
        switchMap((action: PayloadAction<IGetLoginCredentials>): any => {
            const credentials: ILoginInput = {
                username: action.payload.username,
                password: action.payload.password,
            };
            
            return createLoginAPI(credentials).pipe(
                switchMap((resp: any) => {
                    const token = resp.token,
                        refresh_token = resp.refresh_token,
                        decoded = jwtDecode(resp.token),
                        userRoles = (decoded as any).roles;
                    
                    if (isRoleMatched(userRoles, action.payload.role) !== undefined) {
                        return getAccountDataAPI(token).pipe(
                            mergeMap((account: IAccountMeOutput) => {
                                const actions: any[] = [
                                    setAuthState((state$ as any).value.login.username, token, refresh_token, true, userRoles),
                                ];
                                const activeLanguage = (state$ as any).value.saga.activeLanguage,
                                    accountLocale = account.account.locale
                                        ? account.account.locale.split('_')[0]
                                        : activeLanguage
                                        ? activeLanguage
                                        : LanguageLocale.PL;
                                actions.push(setAccountState(account));
                                actions.push(changeActiveLanguage(accountLocale));
                                actions.push(fetchAllDictionaryData());
                                return of(...actions);
                            }),
                            catchError((error: any) => {
                                return of(
                                    setAccountStateFailure('alerts.no_access_error'),
                                    setLoginFailure('alerts.no_access_error'),
                                    setAuthState(null, null, null, false, null),
                                    addAlert(handleApiError(error))
                                );
                            })
                        );
                    } else {
                        return of(setLoginFailure('alerts.wrong_account_error'), addAlert(handleApiError('alerts.wrong_account_error')));
                    }
                }),
                catchError((error: any) => {
                    return of(setLoginFailure(handleApiError(error)?.message), addAlert(handleApiError(error)));
                })
            );
        })
    );

export function isRoleMatched(roles: string[], substring: string): string | undefined {
    return roles.find((element: string) => {
        if (element.includes(substring)) {
            return true;
        }
    });
}

const loginEpic = combineEpics(loginStart);

export default loginEpic;
