import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LanguageLocale, LanguageLocaleType } from '../../../constants/locales';
import { DEFAULT_LOCALE } from '../../../model/account';
import { LoaderType } from "../../../model/common";
import { IRegisterUserInput, UserRegistrationRole, UserRole } from '../../../model/user';
import { IRegistrationInput, registerUser, setRegistrationData } from '../../../store/reducers/authSlice';
import { changeActiveLanguage } from '../../../store/reducers/sagaSlice';
import {
    isAuthenticatedSelector,
    isAuthPageLoadingSelector,
    registrationDataSelector
} from '../../../store/selectors/authSelectors';
import { transformLocaleToLocaleType } from '../../../utils/localeUtils';
import { deepCloneObject } from '../../../utils/objectUtils';
import FormGenerator from '../../FormGenerator';
import Loader from '../../Loader';
import Translation from '../../Translation';
import registrationFormConfig from './registrationFormConfig';

export interface IRegisterProps {
    readonly userRole: UserRole;
    readonly envData: any;
}
const Registration: React.FC<IRegisterProps> = ({userRole, envData}) => {
    const isAuthenticated = useSelector((state) => isAuthenticatedSelector(state)),
        registrationData = useSelector(registrationDataSelector),
        params = new URLSearchParams(location.search),
        username = params.get('email'),
        [locale, setLocale] = useState<string>(LanguageLocaleType.PL.toString()),
        dispatch = useDispatch(),
        navigate = useNavigate(),
        isLoading = useSelector(isAuthPageLoadingSelector),
        returnUrl = `${envData.REACT_APP_URL}/auth/confirm-registration`,
        // ToDo check registration account type
        role =
            userRole === UserRole.EMPLOYEE || userRole === UserRole.USER
                ? UserRegistrationRole.EMPLOYEE
                : null,
        loginUrl = '/auth/login',
        {t} = useTranslation(),
       formConfig = registrationFormConfig(t),

        [initialValues, setInitialValues] = useState<IRegistrationInput>({
            username: username ? username : '',
            password: '',
            locale: '',
            type: role,
            returnUrl: '',
        }),
        [isFormValid, setIsFormValid] = useState(false);


    useEffect(() => {
        if (registrationData) {
            setInitialValues(registrationData);
        }
    }, [registrationData]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.has('locale')) {
            setLocale(params.get('locale') as string);
        }
    }, [location]);

    useEffect(() => {
        if (locale && locale !== DEFAULT_LOCALE) {
            const localeType: LanguageLocale = transformLocaleToLocaleType(locale);
            dispatch(changeActiveLanguage(localeType));
        }
    }, [locale]);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated]);

    const register = (values: any) => {
        const nonMutableValues = deepCloneObject(values);
        const nonMutableLocale = deepCloneObject(locale);
        const registerPayload: IRegisterUserInput = {
            username: nonMutableValues.email,
            password: nonMutableValues.password,
            locale: nonMutableLocale,
            type: role,
            returnUrl: returnUrl,
        };
        dispatch(setRegistrationData(nonMutableValues));
        dispatch(registerUser(registerPayload));
    }

    return (
        <>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
            <div className="auth-form-header">
                <p className="title"><Translation text={'auth.register.title'} /></p>
                <p className="description">
                    <Trans
                        i18nKey="auth.register.description"
                        components={[<Link to={loginUrl} className="link-text" key="login-link" />]}
                    />
                </p>
            </div>
            <div>
            <FormGenerator
                    config={{
                        fields: formConfig,
                        initialValues,
                        onSubmit: register,
                        formId: 'auth-registration-form',
                        formClassName: 'auth-form',
                        submitButtonLabel: 'auth.register.buttons.register'
                    }}
                    isFormValid={(valid) => setIsFormValid(valid)}
                />
            </div>
        </>
    );
};

export default Registration;
