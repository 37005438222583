import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import { reservationsAPI } from '../provider/reservationsAPI';
import { IApiResponseBase, IRawRestQueryParams } from '../../model/base';
import { IReservationOutput } from '../../model/restaurant';

export function getReservationListAPI(
    authToken: string,
    payload?: any,
    params?: IRawRestQueryParams | []
): Observable<IApiResponseBase<IReservationOutput[]>> {
    return reservationsAPI.get('api/reservations', new RestQueryParams(params), {
        Authorization: `Bearer ${authToken}`,
    });
}
