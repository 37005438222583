import React, {Suspense} from 'react';
import styles from './styles.module.scss';
import ReservationForm from '../ReservationForm';
import {Loader, LoaderType, Toast} from 'reservation-common-web';

const PanelHost: React.FC = () => {
    const isLoading = false; // ToDo add isLoading from corresponding slice

    return (
        <div className={styles.pageContainer}>
            <Suspense fallback={<Loader showLoader={isLoading} type={LoaderType.Global} />}>
                <ReservationForm />
            </Suspense>
            <Toast />
            <Loader showLoader={isLoading} type={LoaderType.Global} />
        </div>
    );
};

export default PanelHost;
