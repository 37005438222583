export enum SystemParameterCode {
    RESET_PASSWORD_MAX_LIFETIME = 'reset_password_max_lifetime',
    EMAIL_CONFIRM_MAX_LIFETIME = 'email_confirm_max_lifetime',
    MEDIA_OBJECT_ORPHAN_LIFETIME = 'media_object_orphan_lifetime',
    SYSTEM_INVOICE_DATA = 'system_invoice_data',
    TOC_LINK = 'toc_link',
    PRIVACY_LINK = 'privacy_link',
    STRIPE_PUBLISHABLE_KEY = 'stripe_publishable_key',
    EMAIL = 'email',
    WWW = 'www',
    REFRESH_DICTIONARY_INTERVAL = 'refresh_dictionary_interval'
}

export interface ISystemParameterOutput {
    readonly id: string;
    readonly code: SystemParameterCode;
    readonly value: string;
}
