import * as Yup from 'yup';
import { FormikFieldConfig, FormikFieldTypes, FormikRegexSettings } from '../../../model/form';

const loginFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'email',
            label: t('auth.login.form.labels.email'),
            type: FormikFieldTypes.EMAIL,
            validation: Yup.string().email(t('validation.invalidEmail')).required(t('validation.required')),
            className: '',
        },
        {
            name: 'password',
            label: t('auth.login.form.labels.password'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
    ];
};

export default loginFormConfig;
