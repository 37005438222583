import React from 'react';
import styles from './styles.module.scss';
import {Translation} from 'reservation-common-web';
import {useDispatch} from 'react-redux';
import {ReservationFormWizardSteps, setCurrentStep} from '../../../../store/reducers/reservationFormWizardSlice';

const RoomDetails: React.FC = () => {
    const dispatch = useDispatch();
    const backToForm = () => {
        dispatch(setCurrentStep(ReservationFormWizardSteps.RESERVATION_INFORMATION));
    };
    return (
        <div>
            <div className={styles.header}>
                <button onClick={backToForm} className="btn-back">
                    <span className="btn-icon" />
                    <Translation text={'reservationForm.roomDetails.back'} />
                </button>
            </div>
            <h2 className={styles.title}>
                <Translation text={'reservationForm.roomDetails.roomPlan'} />
            </h2>
            <img src={require('../../../../assets/images/room_plan.png')} alt="Room plans" />
        </div>
    );
};

export default RoomDetails;
