import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator, Translation} from 'reservation-common-web';
import {
    IReservationInformation,
    ReservationFormWizardSteps,
    setCurrentStep,
    setReservationInformation,
} from '../../../../store/reducers/reservationFormWizardSlice';
import {reservationInformationSelector} from '../../../../store/selectors/reservationFormWizardSelectors';
import reservationInformationFormConfig from './reservationInformationFormConfig';

const ReservationInformationStep: React.FC = () => {
    const {t} = useTranslation(),
        rules: {[key: string]: string} = t('reservationForm.reservationDetails.reservationRules', {returnObjects: true});
    const dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = (values: any) => {
        console.log('submit', values);
        const reservationInformationPayload: IReservationInformation = {
            date: values.date,
            count: values.count,
            time: values.time,
            roomId: values.roomId,
        };

        dispatch(setReservationInformation(reservationInformationPayload));
        dispatch(setCurrentStep(ReservationFormWizardSteps.PERSONAL_DETAILS));
    };
    const valuesFromState = useSelector(reservationInformationSelector);

    const initialValues = {
        date: valuesFromState.date ? valuesFromState.date : '',
        count: valuesFromState.count ? valuesFromState.count : '',
        time: valuesFromState.time ? valuesFromState.time : '',
        roomId: valuesFromState.roomId ? valuesFromState.roomId : '',
    };
    const restaurantPlanNavigationButton = (
        <div className="form-control restaurant-plan">
            <button
                type="button"
                className="rooms-plan-button"
                onClick={() => {
                    dispatch(setReservationInformation(initialValues));
                    dispatch(setCurrentStep(ReservationFormWizardSteps.ROOM_DETAILS));
                }}>
                <Translation text={'reservationForm.reservationInformation.roomsPlan'} />
            </button>
        </div>
    );
    return (
        <>
            <div className="reservation-details">
                <p className="reservation-title">
                    <Translation text={'reservationForm.reservationDetails.title'} />
                </p>
                <p className="reservation-greeting">
                    <Translation text={'reservationForm.reservationDetails.greeting'} />
                </p>
                <ul>
                    {Object.keys(rules).map((key: string, index: number) => (
                        <li key={key}>
                            {index + 1}. {rules[key]}
                        </li>
                    ))}
                </ul>
            </div>
            <FormGenerator
                config={{
                    fields: reservationInformationFormConfig(t, restaurantPlanNavigationButton),
                    initialValues: initialValues,
                    onSubmit: handleSubmit,
                    onChange: (values) => {
                        console.log(values, valuesFromState, 'change');
                        if (values !== valuesFromState) {
                            dispatch(setReservationInformation(values));
                        }
                    },
                    formId: 'change-reservation-information-form',
                    formClassName: 'reservation-information-form',
                    submitButtonLabel: 'reservationForm.reservationInformation.next',
                    submitButtonClasses: 'btn-primary btn-save',
                }}
            />
        </>
    );
};

export default ReservationInformationStep;
