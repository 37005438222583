import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IReservationFormViewState} from '../reducers/reservationFormWizardSlice';

export const selectReservationFormViewSlice = (state: RootState) => state.reservationFormView;

export const reservationInformationSelector = createSelector(
    [selectReservationFormViewSlice],
    (state: IReservationFormViewState) => state.reservationInformation
);

export const personalDetailsSelector = createSelector(
    [selectReservationFormViewSlice],
    (state: IReservationFormViewState) => state.personalDetails
);

export const reservationPriceSelector = createSelector(
    [selectReservationFormViewSlice],
    (state: IReservationFormViewState) => state.reservationPrice
);

export const currentStepSelector = createSelector(
    [selectReservationFormViewSlice],
    (state: IReservationFormViewState) => state.currentStep
);

export const isReservationPriceLoadingSelector = createSelector(
    [selectReservationFormViewSlice],
    (state: IReservationFormViewState) => state.isReservationPriceLoading
);

export const isReservationFormViewLoadingSelector = createSelector(
    [selectReservationFormViewSlice],
    (state: IReservationFormViewState) => state.isLoading
);

export const reservationFormViewErrorSelector = createSelector(
    [selectReservationFormViewSlice],
    (state: IReservationFormViewState) => state.error
);

export const shouldReestimateReservationPriceSelector = createSelector(
    [selectReservationFormViewSlice],
    (state: IReservationFormViewState) => state.shouldReestimateReservationPrice
);

export const isNavigationOutsideViewSelector = createSelector(
    [selectReservationFormViewSlice],
    (state: IReservationFormViewState) => state.isNavigationOutsideView
);
