import * as Yup from 'yup';
import {FormikFieldTypes, FormikFieldConfig} from 'reservation-common-web';

const reservationInformationFormConfig = (t: (key: string) => string, restaurantPlanButton: React.ReactNode): FormikFieldConfig[] => {
    return [
        {
            name: 'date',
            label: t('reservationForm.reservationInformation.form.labels.date'),
            type: FormikFieldTypes.DATE,
            validation: Yup.date().required(t('validation.required')),
            className: '',
            dateFormat: 'yyyy-MM-dd',
        },
        {
            name: 'count',
            label: t('reservationForm.reservationInformation.form.labels.count'),
            type: FormikFieldTypes.NUMBER,
            validation: Yup.number().required(t('validation.required')).min(1, t('validation.minGuests')),
            className: '',
        },
        {
            name: 'time',
            label: t('reservationForm.reservationInformation.form.labels.time'),
            type: FormikFieldTypes.TIME,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'roomId',
            label: t('reservationForm.reservationInformation.form.labels.roomId'),
            type: FormikFieldTypes.SELECT,
            validation: Yup.string().required(t('validation.required')),
            className: 'room-select',
            options: [
                {label: t('reservationForm.reservationInformation.form.labels.roomOption1'), value: 'room1'},
                {label: t('reservationForm.reservationInformation.form.labels.roomOption2'), value: 'room2'},
                {label: t('reservationForm.reservationInformation.form.labels.roomOption3'), value: 'room3'},
            ],
        },
        {
            type: FormikFieldTypes.CUSTOM,
            label: '',
            validation: Yup.string(),
            name: 'restaurant-plan',
            customElement: restaurantPlanButton,
            className: '',
        },
    ];
};

export default reservationInformationFormConfig;
