import {createSelector} from '@reduxjs/toolkit';
import {IAccountState} from '../reducers/accountSlice';
import {CommonRootState} from '../reducers';
import {isNotNullOrUndefined, isNullOrUndefined} from '../../utils/runtimeUtils';
import {IAccountBasicInfoOutput, Locale} from '../../model/account';

export const selectAccountState = (state: CommonRootState): IAccountState => {
    return state.account;
};

export const accountSelector = createSelector([selectAccountState], (state) => state.account);

export const accountUserNameSelector = createSelector([selectAccountState], (state) => {
    return isNotNullOrUndefined(state.account?.account?.username) ? state.account?.account?.username : null;
});
export const accountIsInitialisedSelector = createSelector([selectAccountState], (state) => state.isInitialized);
export const accountIsLoadingSelector = createSelector([selectAccountState], (state) => state.isLoading);
export const accountSuccessfulSelector = createSelector([selectAccountState], (state) => state.isActionSuccessful);
export const accountInfoSelector = createSelector([selectAccountState], (state) => state.account.account);
export const canDeleteAccountSelector = createSelector([selectAccountState], (state: IAccountState) => state.canBeDeleted);
export const accountIdSelector = createSelector([accountInfoSelector], (accountInfo: IAccountBasicInfoOutput) => {
    return accountInfo?.id;
});
export const accountLocaleSelector = createSelector([accountInfoSelector], (accountInfo: IAccountBasicInfoOutput) => {
    return accountInfo?.locale;
});
