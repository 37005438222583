import React from 'react';
import {useField, useFormikContext, ErrorMessage} from 'formik';
import Select, {SingleValue} from 'react-select';
import { IMultiselectOption } from '../../../model/form';

interface FormikSelectFieldProps {
    name: string;
    options: IMultiselectOption[];
    isRequired?: boolean;
    isDisabled?: boolean;
    label: string;
    placeholder?: string;
    className?: string;
}

const FormikSelectField: React.FC<FormikSelectFieldProps> = ({name, options, isRequired = false, isDisabled = false, label, placeholder, className}) => {
    const [field, meta, helpers] = useField(name);
    const {setFieldValue} = useFormikContext();

    const handleChange = (newValue: SingleValue<string | IMultiselectOption>) => {
        setFieldValue(name, newValue ? (newValue as IMultiselectOption).value : undefined);
    };

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            minHeight: 'unset',
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            padding: '0',
            margin: '0',
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
        indicatorSeparator: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
    };

    return (
        <div className={`form-control select ${meta.touched && meta.error ? 'invalid' : ''} ${className}`} key={name}>
            <Select
                id={name}
                name={name}
                options={options}
                isDisabled={isDisabled}
                placeholder={placeholder ? placeholder : null}
                onChange={handleChange}
                onBlur={() => helpers.setTouched(true)}
                className={`form-input select ${field.value ? 'filled' : ''} ${placeholder ? 'placeholder-present' : ''}`}
                value={options ? options.find((option) => option.value === field.value) : undefined}
                styles={customStyles}
            />
            <label htmlFor={name} className="form-label">
                {label} {isRequired && <span className="label-required">*</span>}
            </label>
            <ErrorMessage name={name} component="div" className="error-message" />
        </div>
    );
};

export default FormikSelectField;
