import React from 'react';
import styles from './styles.module.scss';
import {LoaderType} from "../../model/common";

interface ILoaderProps {
    showLoader: boolean;
    type?: LoaderType;
    customClass?: string;
}

const Loader: React.FC<ILoaderProps> = ({showLoader, type, customClass}) => {
    const showClass = showLoader ? `${styles.visible}` : `${styles.hidden}`,
        loaderType = type === LoaderType.Local ? `${styles.local}` : '';

    return (
        <div className={`${styles.loaderContainer} ${loaderType} ${showClass} ${customClass || ''}`}>
            <div className={styles.spinnerBorder} />
        </div>
    );
};

export default Loader;
