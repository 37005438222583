import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { PayloadAction } from '@reduxjs/toolkit';
import { renewAuthToken, IRenewAuthToken, setAuthTokens, setAuthStateFailure, logout } from '../reducers/authSlice';
import { addAlert } from '../reducers/alertSlice';
import { createRefreshTokenAPI } from '../../api/auth/createRefreshTokenAPI';
import { AlertType } from '../../model/common';

const sendRefreshToken: Epic = (action$) =>
    action$.pipe(
        ofType(renewAuthToken.type),
        switchMap((action: PayloadAction<IRenewAuthToken>): any => {
            return createRefreshTokenAPI(action.payload.refreshToken).pipe(
                mergeMap((resp: any) => {
                    const actions = [];
                    const decoded = jwtDecode(resp.authToken);

                    actions.push(setAuthTokens(resp.authToken, resp.refreshToken));

                    return of(...actions);
                }),
                catchError((error: any) => {
                    return of(
                        setAuthStateFailure(error.toString()),
                        addAlert({message: 'alerts.expired_token_logout', type: AlertType.WARNING}),
                        logout(true)
                    );
                })
            );
        })
    );

const renewAuthTokenEpic = combineEpics(sendRefreshToken);

export default renewAuthTokenEpic;
