import React from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {AlertType, Loader} from '../../..';
import {addAlert} from '../../../store/reducers/alertSlice';
import {setNewPassword} from '../../../store/reducers/authSlice';
import Translation from '../../Translation';
import {UserRole} from "../../../model/user";
import {LoaderType} from "../../../model/common";
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';

export interface INewPasswordProps {
    readonly isLoading: boolean;
    readonly userRole: UserRole;
}

const NewPassword: React.FC<INewPasswordProps> = ({isLoading, userRole}) => {
    const {id} = useParams(),
        dispatch = useDispatch();

    const changePassword = (values: { password: string }) => {
        if (!id) {
            dispatch(addAlert({message: 'auth.alerts.invalid_reset_password_url', type: AlertType.WARNING}));
            return null;
        }
        dispatch(setNewPassword(id, values.password, userRole));
    }

    return (
        <div>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
            <div className="auth-form-header">
                <p className="title">
                    <Translation text={'auth.change_password.title'} />
                </p>
                <p className="description">
                    <Translation text={'auth.change_password.description'} />
                </p>
            </div>

            <div>
                <Formik
                    initialValues={{ password: '', repeatPassword: '' }}
                    validationSchema={Yup.object({
                        password: Yup.string().required('Required'),
                        repeatPassword: Yup.string().required('Required'),
                    })}
                    onSubmit={changePassword}>
                    <Form className="auth-form">
                        <div className="form-control">
                            <Field name="password" placeholder="" type="password" />
                            <label>
                                <Translation text={'auth.change_password.form.labels.password'} />
                            </label>
                            <ErrorMessage name="password" component="div" />
                        </div>
                        <div className="form-control">
                            <Field name="repeatPassword" placeholder="" type="password" />
                            <label>
                                <Translation text={'auth.change_password.form.labels.repeat_password'} />
                            </label>
                            <ErrorMessage name="repeatPassword" component="div" />
                        </div>
                        <div className="btn-container">
                            <button type="submit" className="btn-primary">
                                <Translation text={'auth.change_password.buttons.change_password'} />
                            </button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default NewPassword;
