import {PayloadAction} from '@reduxjs/toolkit';
import {IMoneyInput, IReservationCreateInput, createBaseReducerSlice} from 'reservation-common-web';

export const enum ReservationFormWizardSteps {
    ROOM_DETAILS = 'roomDetails',
    TERMS_AND_CONDITIONS = 'termsAndConditions',
    RESERVATION_INFORMATION = 'reservationInformation',
    PERSONAL_DETAILS = 'personalDetails',
}

export const reservationFormWizardStepsArray = [
    ReservationFormWizardSteps.ROOM_DETAILS,
    ReservationFormWizardSteps.TERMS_AND_CONDITIONS,
    ReservationFormWizardSteps.RESERVATION_INFORMATION,
    ReservationFormWizardSteps.PERSONAL_DETAILS,
];

export interface IReservationInformation {
    date: string | null;
    count: number | null;
    time: string | null;
    roomId: string | null;
}

export interface IPersonalDetails {
    guestName: string | null;
    phone: string | null;
    email: string | null;
    message: string | null;
    termsAndConditions: boolean | null;
}

export interface IReservationFormViewState {
    isLoading: boolean;
    isReservationPriceLoading: boolean;
    isInitialized: boolean;
    error: string | null;
    reservationInformation: IReservationInformation;
    personalDetails: IPersonalDetails;
    reservationPrice: IMoneyInput | null;
    currentStep: ReservationFormWizardSteps;
    shouldReestimateReservationPrice: boolean;
    isNavigationOutsideView: boolean;
}

export interface ISetReservationInformation {
    reservationInformation: IReservationInformation;
}

export interface ISetPersonalDetails {
    personalDetails: IPersonalDetails;
}
export interface ISetReservationFormViewLoading {
    isLoading: boolean;
}

export interface ISetReservationPriceLoading {
    isReservationPriceLoading: boolean;
}

export interface ISetReservationFormViewError {
    error: string | null;
}

export interface IReservationForm {
    reservationFormInput: IReservationCreateInput;
}

export interface ISetReservationPrice {
    reservationPrice: IMoneyInput | null;
}

const reservationInformationInitialState: IReservationInformation = {
    date: null,
    count: null,
    time: null,
    roomId: null,
};

const personalDetailsInitialState: IPersonalDetails = {
    guestName: null,
    phone: null,
    email: null,
    message: null,
    termsAndConditions: null,
};

const initialState: IReservationFormViewState = {
    isLoading: false,
    isReservationPriceLoading: false,
    isInitialized: false,
    error: null,
    reservationInformation: reservationInformationInitialState,
    personalDetails: personalDetailsInitialState,
    reservationPrice: null,
    currentStep: ReservationFormWizardSteps.RESERVATION_INFORMATION,
    shouldReestimateReservationPrice: false,
    isNavigationOutsideView: false,
};

const reservationFormViewSlice = createBaseReducerSlice({
    name: 'reservationFormView',
    initialState: initialState,
    reducers: {
        setReservationInformation: {
            reducer: (state: IReservationFormViewState, action: PayloadAction<ISetReservationInformation>) => {
                {
                    return {
                        ...state,
                        reservationInformation: action.payload.reservationInformation,
                        isLoading: false,
                    };
                }
            },
            prepare(reservationInformation: IReservationInformation) {
                return {
                    payload: {reservationInformation},
                };
            },
        },

        setPersonalDetails: {
            reducer: (state: IReservationFormViewState, action: PayloadAction<ISetPersonalDetails>) => {
                {
                    return {
                        ...state,
                        personalDetails: action.payload.personalDetails,
                        isLoading: false,
                    };
                }
            },
            prepare(personalDetails: IPersonalDetails) {
                return {
                    payload: {personalDetails},
                };
            },
        },
        setReservationPrice: {
            reducer: (state: IReservationFormViewState, action: PayloadAction<ISetReservationPrice>) => {
                {
                    return {
                        ...state,
                        reservationPrice: action.payload.reservationPrice,
                        isLoading: false,
                    };
                }
            },
            prepare(reservationPrice: IMoneyInput | null) {
                return {
                    payload: {reservationPrice},
                };
            },
        },
        setReservationFormViewError: {
            reducer: (state: IReservationFormViewState, action: PayloadAction<ISetReservationFormViewError>) => {
                {
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                }
            },
            prepare(error: string | null) {
                return {
                    payload: {error},
                };
            },
        },
        setReservationFormViewLoading: {
            reducer: (state: IReservationFormViewState, action: PayloadAction<ISetReservationFormViewLoading>) => {
                {
                    return {
                        ...state,
                        isLoading: action.payload.isLoading,
                    };
                }
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading},
                };
            },
        },

        setReservationFormLocationsLoading: {
            reducer: (state: IReservationFormViewState, action: PayloadAction<ISetReservationPriceLoading>) => {
                {
                    return {
                        ...state,
                        isReservationPriceLoading: action.payload.isReservationPriceLoading,
                    };
                }
            },
            prepare(isReservationPriceLoading: boolean) {
                return {
                    payload: {isReservationPriceLoading},
                };
            },
        },
        setIsOutsideNavigation: {
            reducer: (state: IReservationFormViewState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isNavigationOutsideView: action.payload,
                };
            },
            prepare(isNavigationOutsideView: boolean) {
                return {
                    payload: isNavigationOutsideView,
                };
            },
        },
        setShouldReestimateReservationPrice: {
            reducer: (state: IReservationFormViewState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    shouldRecalculateJobDetails: action.payload,
                };
            },
            prepare(shouldReestimateReservationPrice: boolean) {
                return {
                    payload: shouldReestimateReservationPrice,
                };
            },
        },

        reservationForm: {
            reducer: (state: IReservationFormViewState) => {
                return {
                    ...state,
                    error: null,
                    isLoading: true,
                };
            },
            prepare(reservationFormInput: IReservationCreateInput) {
                return {
                    payload: {reservationFormInput},
                };
            },
        },

        setCurrentStep: {
            reducer: (state: IReservationFormViewState, action: PayloadAction<ReservationFormWizardSteps>) => {
                return {
                    ...state,
                    currentStep: action.payload,
                };
            },
            prepare(currentStep: ReservationFormWizardSteps) {
                return {
                    payload: currentStep,
                };
            },
        },
        resetToInitialReservationFormViewState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setReservationInformation,
    setPersonalDetails,
    setReservationPrice,
    setReservationFormViewError,
    setReservationFormViewLoading,
    setReservationFormLocationsLoading,
    setIsOutsideNavigation,
    setShouldReestimateReservationPrice,
    reservationForm,
    setCurrentStep,
    resetToInitialReservationFormViewState,
} = reservationFormViewSlice.actions;

export default reservationFormViewSlice.reducer;
