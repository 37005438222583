import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import { reservationsAPI } from '../provider/reservationsAPI';
import { IApiSingleResponseBase } from '../../model/base';

export const deleteRestaurantAPI: ApiOperationResponseFunction<null> = (
    authToken: string,
    payload: {
        restaurantId: string;
    }
): Observable<IApiSingleResponseBase<null>> => {
    return reservationsAPI.delete(`api/restaurants/${payload.restaurantId}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
