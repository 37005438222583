import React from 'react';
import {Translation} from 'reservation-common-web';
import styles from './styles.module.scss';
import {useDispatch} from 'react-redux';
import {ReservationFormWizardSteps, setCurrentStep} from '../../../../store/reducers/reservationFormWizardSlice';

const TermsAndConditions: React.FC = () => {
    const dispatch = useDispatch();
    const backToForm = () => {
        dispatch(setCurrentStep(ReservationFormWizardSteps.PERSONAL_DETAILS));
    };
    return (
        <div>
            <div className={styles.header}>
                <button onClick={backToForm} className="btn-back">
                    <span className="btn-icon" />
                    <Translation text={'reservationForm.roomDetails.back'} />
                </button>
            </div>
            <h2 className={styles.title}>
                <Translation text={'reservationForm.termsAndConditions.title'} />
            </h2>
            <div className={styles.conditionsContainer}>
                <p>
                    <Translation text={'reservationForm.termsAndConditions.conditions'} />
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditions;
