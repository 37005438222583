import { IFileOutput, ILocaleString, IMoneyOutput, IPhoneOutput } from './common';

export enum Locale {
    LOCALE_EN = 'en_US',
    LOCALE_PL = 'pl_PL',
    LOCALE_UA = 'uk_UA',
}

export enum ShortLocale {
    LOCALE_EN = 'en',
    LOCALE_PL = 'pl',
    LOCALE_UA = 'uk',
}

export const DEFAULT_LOCALE: Locale = Locale.LOCALE_PL;

export type IAccountMeOutput = {
    readonly account: IAccountBasicInfoOutput;
    readonly status: {
        readonly employee: IEmployeeOutput | null;
        readonly company: ICompanyOutput | null;
    }
    readonly restaurant: IRestaurantOutput;
};


export type IOpenHoursOutput = {
    readonly _0?: IDayOpenHoursOutput | null;
    readonly _1?: IDayOpenHoursOutput | null;
    readonly _2?: IDayOpenHoursOutput | null;
    readonly _3?: IDayOpenHoursOutput | null;
    readonly _4?: IDayOpenHoursOutput | null;
    readonly _5?: IDayOpenHoursOutput | null;
    readonly _6?: IDayOpenHoursOutput | null;
};

export type IDayOpenHoursOutput = {
    readonly from: string;
    readonly to: string;
};

export type IRestaurantOutput = {
    readonly id: string;
    readonly name: string;
    readonly address: string;
    readonly email: string;
    readonly phone: IPhoneOutput;
    readonly slug: string;
    readonly configuration: IRestaurantConfigurationOutput;
    readonly rooms: IRoomOutput[];
};

export type IRestaurantConfigurationOutput = {
    readonly id: string;
    readonly privacyPolicy: string;
    readonly openHours: IOpenHoursOutput;
    readonly color: string;
    readonly background: IFileOutput;
    readonly avatar: IFileOutput;
    readonly title: string;
    readonly shortDescription: string;
    readonly initialDescription: string;
};


export type IRoomOutput = {
    readonly id: string;
    readonly name: string | null;
    readonly default: boolean;
    readonly maxCount: number;
    readonly maxCountPerReservation: number;
    readonly hourStart: string;
    readonly hourEnd: string;
    readonly step: number;
    readonly prepayRequired: boolean;
    readonly prepayPerPerson: IMoneyOutput;
};
export type IEmployeeOutput = {
    readonly id: string | null;
    readonly active: boolean;
};
export type ICompanyOutput = {
    readonly id: string | null;
    readonly active: boolean;
    readonly onboarding: boolean;
    readonly stripeOnboardingCompleted: boolean;
    readonly stripePendingVerification: boolean;
    readonly stripeDetailsSubmitted: boolean;
    readonly paymentAccountId: string | null;
};
export type IAccountBasicInfoOutput = {
    readonly id: string;
    readonly userId: string;
    readonly username: string;
    readonly locale: string;
    readonly misc: any;
};

export type IMiscOutput = {
    readonly theme: string | null;
};
export type IAccountLocaleInput = {
    locale: ILocaleString;
};

export type IAccountMiscInput = {
    misc: any;
};

export type IAccountProfileInput = {
    username: string;
    returnUrl: string;
};

export interface IAvatarPayload {
    mediaObjectId: string;
}

