import { ErrorMessage, Field, useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Subscription, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { createMediaObjectAPI } from '../../../api/mediaObject/createMediaObjectAPI';
import DefaultAvatar from '../../../assets/icons/default-avatar';
import { FormikFieldConfig, IFileUploadListElement } from '../../../model/form';
import { authTokenSelector } from '../../../store/selectors/authSelectors';
import AuthorizedImage from '../../AuthorizedImage';
import { FieldProps } from 'formik';

const FileFormControl: React.FC<FormikFieldConfig> = ({
    name,
    label,
    acceptedFileTypes,
    placeholder,
    className,
    getFileIdValue,
    fileUploadRequirements,
}) => {
    const [mediaItem, setMediaItem] = useState<IFileUploadListElement | null>(null);
    const [field, meta, helpers] = useField(name);
    const { setFieldValue } = useFormikContext();

    const { t } = useTranslation(),
        authToken: string = useSelector(authTokenSelector),
        [isProcessing, setIsProcessing] = useState<boolean>(false),
        getFileName = () => (field.value?.name ? field.value.name : mediaItem?.name ? mediaItem.name : ''),
        getFileId = () => (mediaItem?.fileKey ? mediaItem.fileKey : field.value?.fileKey ? field.value?.fileKey : ''),
        subscriptions: Subscription[] = [],
        placeholderText = placeholder ? t(placeholder) : '';
    useEffect(() => {
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);
    const fileHandleChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        if (!e.target.files) return;
        const files: File[] = Array.from(e.target.files);

        setIsProcessing(true);
        if (files.length > 0) {
            const documentImage = files[0];
            const documentName = documentImage.name;
            const formData = new FormData();
            formData.append('file', documentImage);
            formData.append('public', 'true');
            subscriptions.push(
                createMediaObjectAPI(authToken, formData)
                    .pipe(
                        tap((resp: any) => {
                            if (resp.response) {
                                const mediaItem = {
                                    name: documentName ? documentName : resp.response?.fileName,
                                    fileKey: resp.response.id,
                                    url: resp.response.contentUrl ? resp.response.contentUrl : resp.response.fileUrls.original,
                                };
                                setMediaItem(mediaItem);
                                setFieldValue(name, getFileIdValue ? resp.response.id : mediaItem);
                            }
                            setIsProcessing(false);
                        }),
                        catchError((error) => {
                            setIsProcessing(false);
                            return of(...error);
                        })
                    )
                    .subscribe()
            );
        }
    };

    console.log(mediaItem, 'mediaItem');

    return (
        <div className={`file-upload-input-wrapper ${className}`} key={`file-input-wrapper-${name}`}>
            <div className={`file-upload-input-label ${className}`} key={`file-input-wrapper-label-${name}`}>
                <label htmlFor={name} className="form-label" >
                    {t(label)}
                </label>
            </div>
            {mediaItem && mediaItem.url &&
                <AuthorizedImage url={mediaItem?.url} alt={mediaItem?.name} default={null} />
            }
            <div>
                <div className={`file-upload-input ${className}`}>
                    <Field name={field.name}>
                        {({ field, form }: FieldProps) => (
                            <div className="file-upload-field">
                                <input
                                    type="file"
                                    {...field}
                                    value={''}
                                    className="form-input color-picker-input"
                                    onChange={(e) => fileHandleChange(e, name)}
                                />

                                <DefaultAvatar />
                                <span>Kliknij aby dodać lub przeciagnij i upusc</span>
                                {fileUploadRequirements && <>
                                    <ul className="file-input-requirement-list">
                                        {fileUploadRequirements.map((requirement, index) => (
                                            <li key={`file-input-wrapper-requirement-${index}`}>{requirement}</li>
                                        ))}
                                    </ul>
                                </>
                                }

                            </div>
)}
                                            </Field>

                </div>
                <ErrorMessage name={name} component="div" />
            </div>
        </div>
    );
};
export default FileFormControl;
