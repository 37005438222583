import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {createReduxHistoryContext} from 'redux-first-history';
import {CombinedState} from '@reduxjs/toolkit';
import {
    accountSlice,
    alertSlice,
    authSlice,
    changePasswordSlice,
    CommonRootState,
    countrySlice,
    loaderSlice,
    loginSlice,
    modalSlice,
    sagaSlice,
    systemParametersSlice,
} from 'reservation-common-web';
import reservationFormWizardSlice, {IReservationFormViewState} from './reservationFormWizardSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    saga: sagaSlice,
    country: countrySlice,
    router: routerReducer,
    loader: loaderSlice,
    account: accountSlice,
    modal: modalSlice,
    systemParameters: systemParametersSlice,
    reservationFormView: reservationFormWizardSlice,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    return appReducer(state, action);
};

export type RootState = CombinedState<{
    reservationFormView: IReservationFormViewState;
}> &
    CommonRootState;

export default rootReducer;
