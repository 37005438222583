import React from 'react';
import {Translation, formatDateToString} from 'reservation-common-web';
import {useSelector} from 'react-redux';
import {IReservationInformation} from '../../../../../store/reducers/reservationFormWizardSlice';
import {reservationInformationSelector} from '../../../../../store/selectors/reservationFormWizardSelectors';

const ReservationDetails: React.FC = () => {
    const reservationDetails: IReservationInformation = useSelector(reservationInformationSelector);
    console.log(
        reservationDetails,
        'reservation det init',
        reservationDetails.date,
        reservationDetails.time,
        reservationDetails.count,
        reservationDetails.roomId
    );

    const formatTime = (dateString: string) => {
        const date = new Date(dateString);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const reservationDetailsInitData = [
        {
            label: 'reservationForm.personalDetails.reservation.date',
            value: reservationDetails.date ? formatDateToString(new Date(reservationDetails.date)) : '',
        },
        {
            label: 'reservationForm.personalDetails.reservation.time',
            value: reservationDetails.time ? formatTime(reservationDetails.time) : '',
        },
        {label: 'reservationForm.personalDetails.reservation.count', value: reservationDetails.count || ''},
        {label: 'reservationForm.personalDetails.reservation.room', value: reservationDetails.roomId || ''},
    ];

    console.log(reservationDetailsInitData, 'reservationDetailsInitData');

    const renderItem = (label: string, value: string | number) => {
        console.log('label', label, value, 'value');
        return (
            <div className="reservation-item" key={label}>
                <p className="label">
                    <Translation text={label} />
                </p>
                <p className="value">{value}</p>
            </div>
        );
    };

    return (
        <div className="reservation">
            {reservationDetailsInitData.map((detail) => renderItem(detail.label, detail.value ? detail.value : ''))}
        </div>
    );
};

export default ReservationDetails;
