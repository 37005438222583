import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { initApp } from '../../store/reducers/authSlice';
import {UserRole} from "../../model/user";
import {isAuthenticatedSelector} from "../../store/selectors/authSelectors";

export interface IPrivateRouteProps {
    children: any;
    userRole: UserRole;
    authUrl?: string;
    [key: string]: any;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children, userRole, authUrl = '/auth/login' }) => {
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (!isProductionMode() && location && location.search) {
            const params = new URLSearchParams(location.search);
            if (params.has('token')) {
                setAuthToken(params.get('token') as string);
            }
        }
    }, [location]);

    const setAuthToken = (token: string | null) => {
        if (!token) {
            return;
        }

        dispatch(initApp(token, userRole));
    };

    const isProductionMode = (): boolean => {
        return process.env.REACT_APP_ENV === 'prod';
    };

    if (!isAuthenticated) {
        return <Navigate replace to={authUrl} />;
    }

    return children;
};

export default PrivateRoute;
