import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useLocation, Navigate} from 'react-router-dom';
import {isAuthPageLoadingSelector} from '../../store/selectors/authSelectors';
import Toast from '../Toast';
import ConfirmRegistration from './ConfirmRegistration';
import Login from './Login';
import NewPassword from './NewPassword';
import Registration from './Registration';
import ResetPassword from './ResetPassword';
import {loginWithToken} from '../../store/reducers/loginSlice';
import {UserRole} from "../../model/user";
import AuthLayout from "./AuthLayout";

export interface IAuthPanelProps {
    userRole: UserRole;
    envData: any;
}

const AuthPanel: React.FC<IAuthPanelProps> = ({userRole, envData}) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(isAuthPageLoadingSelector),
        location = useLocation();

    const setAuthToken = (token: string | null) => {
        if (!token) {
            return;
        }

        dispatch(loginWithToken(token, userRole));
    };

    useEffect(() => {
        const isProduction = process.env.REACT_APP_ENV === 'prod';

        if (!isProduction) {
            if (location && location.search) {
                const params = new URLSearchParams(location.search);

                if (params.has('token')) {
                    setAuthToken(params.get('token') as string);
                }
            }
        }
    }, [location]);
    return (
        <div>
            <Routes key="basic-routes">
                <Route key="login-route" path="/login" element={
                    <AuthLayout userRole={userRole}>
                        <Login userRole={userRole} key="login" />
                    </AuthLayout>}
                />

                <Route
                    key="registration-route"
                    path="/register"
                    element={
                        <AuthLayout userRole={userRole}>
                            <Registration userRole={userRole} envData={envData} key={'registration'} />
                        </AuthLayout>
                    }
                />

                <Route
                    key="reset-password-route"
                    path="/reset-password"
                    element={
                        <AuthLayout userRole={userRole}>
                            <ResetPassword
                                userRole={userRole}
                                envData={envData}
                                isLoading={isLoading}
                                key="reset-password"
                            />
                        </AuthLayout>
                    }
                />

                <Route
                    key="confirm-registration-route"
                    path="/confirm-registration/:id"
                    element={
                        <AuthLayout userRole={userRole}>
                            <ConfirmRegistration isLoading={isLoading} userRole={userRole} key="confirm-registration" />
                        </AuthLayout>
                    }
                />

                <Route
                    key="new-password-route"
                    path="/new-password/:id"
                    element={
                        <AuthLayout userRole={userRole}>
                            <NewPassword isLoading={isLoading} userRole={userRole} key="new-password" />
                        </AuthLayout>
                    }
                />

                <Route key="not-found-route" path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
            <Toast />
        </div>
    );
};

export default AuthPanel;
