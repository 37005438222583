import React from 'react';
import Translation from '../../Translation';

const NotFound = () => {
    return (
            <div className="maintenance-container">
                <h2 className="header">404</h2>
                <p className="description">
                    <Translation text="maintenance.page_not_found" />
                </p>
            </div>
    );
};

export default NotFound;
