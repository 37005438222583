import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { createDictionaryEpic, createFetchListEpic, getErrorMessage } from '../../utils/epicUtils';
import { addAlert } from '../reducers/alertSlice';
import { changeCountry, changeIsCountryLoading, fetchAllDictionaryData, fetchCountries } from '../reducers/countrySlice';
import { handleApiError } from '../../utils/errorHandlingUtils';
import { getDictionaryDataAPI } from '../../api/dictionary/getDictionaryDataAPI';
import { AlertType, IModelCountry } from '../../model/common';
import { DictionaryName, IModelDictionaryDatum } from '../../model/dictionaryDatum';
import { ListSuccessActionsFunction } from '../../api/base/apiConnectionInfrastructure';

const fetchAllDictionaryDataEpic: Epic = (action$) =>
    action$.pipe(
        ofType(fetchAllDictionaryData.type),
        concatMap(() => {
            return of(
                fetchCountries()
            );
        }),
        catchError((error: any) => of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING})))
    );

export const fetchListErrorActions = (error: any, setSliceError: any, setSliceIsLoading: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    let errorActions = [addAlert(errorObj)];
    if (setSliceError) {
        errorActions.push(setSliceError(errorObj?.message));
    }
    if (setSliceIsLoading) {
        errorActions.push(setSliceIsLoading(false));
    }
    return errorActions;
};

const countryDictionarySuccessActions: ListSuccessActionsFunction<IModelDictionaryDatum> = (countriesArray: IModelDictionaryDatum[]) => {
    return [changeCountry(countriesArray), changeIsCountryLoading(false)];
};

const fetchCountriesEpic = createDictionaryEpic<IModelCountry>(
    getDictionaryDataAPI,
    countryDictionarySuccessActions,
    fetchListErrorActions,
    fetchCountries().type,
    DictionaryName.COUNTRY
);

const dictionaryDataEpic = combineEpics(
    fetchAllDictionaryDataEpic,
    fetchCountriesEpic,
);

export default dictionaryDataEpic;
