import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator, Translation} from 'reservation-common-web';
import {personalDetailsSelector} from '../../../../store/selectors/reservationFormWizardSelectors';
import personalDetailsFormControl from './personalDetailsFormControl';
import ReservationDetails from './ReservationDetails';
import {ReservationFormWizardSteps, setCurrentStep, setPersonalDetails} from '../../../../store/reducers/reservationFormWizardSlice';

const PersonalDetailsStep: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = (e: any) => {
        e.preventDefault();
        // Handle form submission
    };

    const personalDetailsFromState = useSelector(personalDetailsSelector);
    const customElement = (
        <div className="reservation-fee">
            <p>
                <Trans
                    i18nKey="reservationForm.reservationInformation.reservationFee"
                    values={{amount: 200, currency: 'zł'}}
                    components={[<span key={'reservation-fee'} />]}
                />
            </p>
        </div>
    );
    const valuesFromState = useSelector(personalDetailsSelector);

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const showTermsAction = () => {
        dispatch(setCurrentStep(ReservationFormWizardSteps.TERMS_AND_CONDITIONS));
    };
    const initialValues = {
        guestName: personalDetailsFromState ? personalDetailsFromState.guestName : '',
        phone: personalDetailsFromState ? personalDetailsFromState.phone : '',
        email: personalDetailsFromState ? personalDetailsFromState.email : '',
        message: personalDetailsFromState ? personalDetailsFromState.message : '',
        termsAndConditions: personalDetailsFromState ? personalDetailsFromState.termsAndConditions : false,
    };
    return (
        <>
            <div className="reservation-details">
                <p className="reservation-title">
                    <Translation text={'reservationForm.personalDetails.reservation.title'} />
                </p>
                <ReservationDetails />
            </div>
            <FormGenerator
                config={{
                    fields: personalDetailsFormControl(t, customElement, showTermsAction),
                    initialValues: initialValues,
                    onSubmit: handleSubmit,
                    onChange: (values) => {
                        console.log(values, valuesFromState, 'change');
                        if (values !== valuesFromState) {
                            dispatch(setPersonalDetails(values));
                        }
                    },
                    formId: 'change-personal-details-form',
                    formClassName: '',
                }}
            />

            <div className="btn-container">
                <button type="submit" onClick={handleSubmit} form="change-personal-details-form" className="btn-primary">
                    <span className="btn-icon plus-icon" />
                    <Translation text={'reservationForm.personalDetails.placeReservation'} />
                </button>
            </div>
        </>
    );
};

export default PersonalDetailsStep;
