import * as Yup from 'yup';
import { FormikFieldConfig, FormikFieldTypes, FormikRegexSettings } from '../../../model/form';

const registrationFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'plan',
            label: t('auth.register.form.labels.plan'),
            type: FormikFieldTypes.SELECT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
            options: [
                {label: t('auth.register.form.plan_options.free'), value: 'employee'},
            ],
        },
        {
            name: 'email',
            label: t('auth.register.form.labels.email'),
            type: FormikFieldTypes.EMAIL,
            validation: Yup.string().email(t('validation.invalidEmail')).required(t('validation.required')),
            className: '',
        },
        {
            name: 'password',
            label: t('auth.register.form.labels.password'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        }, 
        {
            name: 'repeat_password',
            label: t('auth.register.form.labels.repeat_password'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string().required(t('validation.required')).oneOf([Yup.ref('password')], t('validation.passwordsMustMatch')),
            className: '',
        },
        {
            name: 'terms_and_conditions',
            label: t('auth.register.form.labels.terms_and_conditions'),
            type: FormikFieldTypes.CHECKBOX,
            validation: Yup.bool().oneOf([true], t('validation.mustAcceptTermsAndConditions')).required(t('validation.required')),
            className: '',
        },
    ];
};

export default registrationFormConfig;
