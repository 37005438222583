const pl = {
    reservationForm: {
        title: 'Rezerwuj stolik',
        reservationDetails: {
            title: 'Przeczytaj uważnie',
            greeting: 'Witaj w naszym automatycznym systemie rezerwacji!',
            reservationRules: {
                rule1: 'Rezerwacja powyżej 20 osób -  pisz na messengera Cybermachina Warszawa :)',
                rule2: 'Rezerwacja wymaga  przedpłaty, całość tej kwoty będziecie mogli wydać u nas w dniu  rezerwacji :) Niewykorzystana przedpłata przepada.',
                rule3: 'Nawet jeżeli nie  możemy już zarezerwować miejsca na dany dzień nadal warto wpaść.  Rezerwujemy tylko część lokalu, a stoliki często rotują. ',
                rule4: 'Rezerwacja  jest ważna do 30 minut od umówionej godziny, prosimy o punktualność!',
                rule5: 'Anulowanie rezerwacji jest możliwe do godziny 14:00 dnia rezerwacji,  prosimy o telefon na 797 121 336. Do Cybermachiny wejść mogą tylko osoby  pełnoletnie :)',
            },
        },
        roomDetails: {
            back: 'Powrót',
            roomPlan: 'Plan Lokalu',
        },
        termsAndConditions: {
            title: 'Polityka Prywatności',
            conditions:
                'Administratorem danych,  będziemy My, czyli Cybermachina Michał Gomoła.  Dane będą przetwarzane w celu realizacji rezerwacji oraz zamówienia a  także mogą być przetwarzane w celu marketingu bezpośredniego naszych  produktów i usług. Podstawą prawną przetwarzania jest uzasadniony  interes Administratora . Odbiorcami Twoich danych czyli podmiotami,  którym będziemy mogli przekazać Twoje dane zgodnie z obowiązującym  prawem będą podmioty pomagające Nam w działaniach związanych z opisanym  wyżej marketingiem bezpośrednim produktów i usług własnych oraz inne  podmioty uprawnione do uzyskania danych na podstawie obowiązującego  prawa. Przysługuje Ci prawo dostępu do treści swoich danych i ich  poprawiania a także wyrażenia sprzeciwu co do ich przetwarzania, jak  również wniesienia skargi do organu nadzorczego.',
        },
        reservationInformation: {
            form: {
                labels: {
                    date: 'Wybierz datę',
                    count: 'Wybierz liczbę osób',
                    time: 'Początek rezerwacji',
                    roomId: 'Wybierz salę',
                    roomOption1: 'Sala 1',
                    roomOption2: 'Sala 2',
                    roomOption3: 'Sala 3',
                },
            },
            roomsPlan: 'Plan lokalu',
            next: 'Dalej',
            reservationFee: 'Wymagana kaucja: <0>{{amount}} {{currency}}</0>',
        },
        personalDetails: {
            reservation: {
                title: 'Twoja rezerwacja',
                date: 'Data',
                time: 'Godzina',
                count: 'Liczba osób',
                room: 'Sala',
            },
            form: {
                labels: {
                    guestName: 'Imię',
                    phone: 'Numer telefonu',
                    email: 'E-mail',
                    message: 'Wiadomość',
                    termsAndConditions: `Zapoznałem się z <0>Polityką Prywatności</0>.`,
                },
            },
            placeReservation: 'Rezerwuj i opłać',
        },
    },
    footer: {
        copyright: `Reservation Spot {{date}} © All right reserved`,
    },
    maintenance: {
        page_not_found: 'Błąd - nie znaleziono strony',
    },
    alerts: {
        warning: 'Uwaga!',
        error: 'Błąd!',
        success: 'Sukces!',
        info: 'Informacja',
        authError: 'Nazwa użytkownika lub hasło są nieprawidłowe.',
        no_access_error: 'Nie masz uprawnień do przeglądania tej strony.',
        wrong_account_error: 'Nie możesz się zalogować do tej aplikacji za pomocą tego konta.',
        base_error: 'Wystąpił błąd, skontaktuj się z administratorem.',
        login_error: 'Wystąpił błąd przy logowaniu, spróbuj ponownie.',
        try_again: 'Spróbuj ponownie',
        unknown: 'Wystąpił nieznany błąd.',
        access_denied: 'Nie masz uprawnień.',
        payment_method_attached: 'Metoda płatności została pomyślnie dodana.',
        payment_method_deleted: 'Metoda płatności została usunięta.',
        account_not_confirmed: 'Konto nie zostało potwierdzone.',
        account_not_active: 'Konto nie jest aktywne.',
        account_deleted: 'Konto zostało usunięte.',
        account_is_invitation: 'Nieprawidłowe dane.',
        invalid_or_expired_token: 'Twój token wygasł lub jest nieprawidłowy.',
        expiredTokenLogout: 'Twoja sesja wygasła. Zostałeś wylogowany.',
        password_changed: 'Hasło zostało zmienione',
        bad_password: 'Niepoprawne hasło',
    },
};

export default pl;
