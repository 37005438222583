import React, {CSSProperties, ReactNode} from 'react';

export interface IBasicModalProps {
    isModalShown: boolean;
    closeModal: () => void;
    children?: ReactNode;
    isModalLocal?: boolean;
    isModalCustom?: boolean;
    wrapperInlineStyles?: CSSProperties;
}

const BasicModal = (props: IBasicModalProps) => (
    <div className={`modal-wrapper
                     ${props.isModalShown ? "visible" : ""}
                     ${props.isModalLocal ? "local" : ""}
                     ${props.isModalCustom ? "custom" : ""}`}
         style={props.wrapperInlineStyles}>
        <div className={`modal-background ${props.isModalLocal ? "bg-local" : ""}`}
             onClick={() => props.closeModal && props.closeModal()} />
        <div className="modal-content-container">
            {props.children}
        </div>
    </div>
);

BasicModal.Header = (props: React.PropsWithChildren<{}>) => (
    <div className="modal-header">
        {props.children}
    </div>
);

BasicModal.Body = (props: React.PropsWithChildren<{}>) => (
    <div className="modal-body">
        {props.children}
    </div>
);

BasicModal.Footer = (props: React.PropsWithChildren<{}>) => (
    <div className="modal-footer">
        {props.children}
    </div>
);

export default BasicModal;