import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PayloadAction} from '@reduxjs/toolkit';
import {addAlert} from '../reducers/alertSlice';
import {
    changePasswordStart,
    IChangePasswordStart,
    changeIsPasswordChanged,
    setChangePasswordFailure,
    changePasswordLoading,
} from '../reducers/changePasswordSlice';
import {createChangePasswordAPI} from '../../api/user/createChangePasswordAPI';
import {authTokenSelector} from '../selectors/authSelectors';
import { IChangeUserPasswordInput } from '../../model/user';
import { AlertType } from '../../model/common';

const changePasswordStartEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(changePasswordStart.type),
        switchMap((action: PayloadAction<IChangePasswordStart>): any => {
            const authToken = authTokenSelector(state$.value) || '';
            const passwordPayload: IChangeUserPasswordInput = {
                oldPassword: action.payload.oldPassword,
                newPassword: action.payload.newPassword,
            };
            return createChangePasswordAPI(passwordPayload, authToken).pipe(
                mergeMap(() => {
                    return of(
                        changeIsPasswordChanged(true),
                        addAlert({message: 'alerts.password_changed'}),
                        changePasswordLoading(false)
                    );
                }),
                catchError((error: any) => {
                    return of(
                        setChangePasswordFailure(error.toString()),
                        addAlert({message: error.response['hydra:description'], type: AlertType.WARNING})
                    );
                })
            );
        })
    );

const resetPasswordEpic = combineEpics(changePasswordStartEpic);

export default resetPasswordEpic;
