import {IApiSingleResponseBase} from '../../model/base';
import {reservationsAPI} from '../provider/reservationsAPI';
import {RestQueryParams} from '../base/queryParams';
import {Observable} from 'rxjs';

export function getStreamMediaObjectAPI(authToken: string, mediaObjectId: string): Observable<IApiSingleResponseBase<any>> {
    return reservationsAPI.get(`api/media_objects/${mediaObjectId}/stream`, new RestQueryParams(), {
        Accept: 'application/ld+json',
        'Content-type': 'application/pdf',
        Authorization: `Bearer ${authToken}`,
    });
}
