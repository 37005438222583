import {Observable} from 'rxjs';
import {reservationsAPI} from '../provider/reservationsAPI';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {RestQueryParams} from '../base/queryParams';
import {IPaymentClientSecretOutput} from '../../model/payment';
import {IApiSingleResponseBase} from '../../model/base';

export const getPaymentClientSecretAPI: ApiOperationResponseFunction<IPaymentClientSecretOutput> = (
    authToken: string,
    payload: {paymentAccountId: string}
): Observable<IApiSingleResponseBase<IPaymentClientSecretOutput>> => {
    return reservationsAPI.put(`api/payment_accounts/${payload.paymentAccountId}/stripe/setup_intent`, {}, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
