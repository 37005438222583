import React from 'react';
import {Translation} from 'reservation-common-web';
import ReservationWizard from './ReservationWizard';
import styles from './styles.module.scss';

const ReservationForm: React.FC = () => {
    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <div className={styles.logo}>
                    <img src={require('../../assets/images/logo.png')} alt="Logo image" />
                </div>
                <div className={styles.placeDetails}>
                    <h1 className={styles.title}>
                        <Translation text={'reservationForm.title'} />
                    </h1>
                    <p className={styles.details}>{'Cybermachina Warszawa'}</p>
                    <p className={styles.details}>{'Nowy Świat 54/56'}</p>
                </div>
            </div>
            <div className={styles.cardBody}>
                <ReservationWizard />
            </div>
        </div>
    );
};

export default ReservationForm;
