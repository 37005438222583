import {useSelector} from 'react-redux';
import {ReservationFormWizardSteps} from '../../../store/reducers/reservationFormWizardSlice';
import {currentStepSelector} from '../../../store/selectors/reservationFormWizardSelectors';
import PersonalDetailsStep from './PersonalDetailsStep';
import ReservationInformationStep from './ReservationInformationStep';
import RoomDetails from './RoomDetails';
import TermsAndConditions from './TermsAndConditions';

const ReservationWizard = () => {
    const currentStep = useSelector(currentStepSelector);

    const renderStep = () => {
        switch (currentStep) {
            case ReservationFormWizardSteps.TERMS_AND_CONDITIONS:
                return <TermsAndConditions />;
            case ReservationFormWizardSteps.PERSONAL_DETAILS:
                return <PersonalDetailsStep />;
            case ReservationFormWizardSteps.ROOM_DETAILS:
                return <RoomDetails />;
            default:
                return <ReservationInformationStep />;
        }
    };

    return <div className="wizard-container">{renderStep()}</div>;
};

export default ReservationWizard;
