import React from 'react';
import {Field, useField} from 'formik';
import {FormikFieldConfig} from '../../../model/form';
import Translation from '../../Translation';
import {Trans} from 'react-i18next';

const SwitchField: React.FC<FormikFieldConfig> = ({name, label, sublabel, className, isLabelWithLink, linkAction}) => {
    const [field, meta, helpers] = useField(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        helpers.setValue(checked);
    };

    return (
        <div className={`form-control switch ${className ? className : ''}`}>
            <label className={`switch-label`}>
                <Field type="checkbox" {...field} checked={field.value} onChange={handleChange} className="switch-input" />
                <div className="switch-slider" />
                <span className="switch-label-text">
                    {isLabelWithLink ? (
                         <Trans
                         i18nKey={label}
                         components={[<span key={'conditions-link'} className="link-label" onClick={linkAction} />]}
                     />
                    ) : (
                        <Translation text={label} />
                    )}
                    {sublabel && (
                        <span className="switch-sublabel">
                            <Translation text={sublabel} />
                        </span>
                    )}
                    </span>
            </label>
            {meta.touched && meta.error ? (
                <div className="error-message" style={{color: 'red'}}>
                    {meta.error}
                </div>
            ) : null}
        </div>
    );
};

export default SwitchField;
