import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
    IConfirmRegistrationOutput,
    ISetNewPasswordInput,
    handleApiError
} from '../..';
import { addAlert } from '../reducers/alertSlice';
import jwtDecode from 'jwt-decode';
import {
    changeIsAuthPageLoading,
    confirmRegistration, IConfirmRegistration, IRegisterUser,
    IRequestNewPassword,
    ISetNewPassword,
    registerUser,
    requestNewPassword,
    setNewPassword,
    setRegistrationData
} from '../reducers/authSlice';
import {loginWithToken} from '../reducers/loginSlice';
import {createRegisterAPI} from '../../api/user/createRegisterAPI';
import {push} from 'react-router-redux';
import {createRequestResetPasswordAPI} from '../../api/user/createRequestResetPasswordAPI';
import {createSetNewPasswordAPI} from '../../api/user/createSetNewPasswordAPI';
import {createConfirmRegistrationAPI} from '../../api/user/createConfirmRegistrationAPI';
import {AlertType} from '../../model/common';
import { IApiSingleResponseBase } from '../../model/base';
import { IApiError } from '../../model/auth';

const registerUserEpic: Epic = (action$) =>
    action$.pipe(
        ofType(registerUser.type),
        switchMap((action: PayloadAction<IRegisterUser>) => {
            return createRegisterAPI(action.payload.registrationPayload, action.payload.invitationToken).pipe(
                switchMap(() => of(...successActions('auth.alert.registrationSuccess'), setRegistrationData(null))),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );

const confirmRegistrationEpic: Epic = (action$) =>
    action$.pipe(
        ofType(confirmRegistration.type),
        switchMap((action: PayloadAction<IConfirmRegistration>) => {
            return createConfirmRegistrationAPI(action.payload.registrationToken).pipe(
                switchMap((response: IApiSingleResponseBase<IConfirmRegistrationOutput>) => {
                    const token = response.tokens.token,
                        refresh_token = response.tokens.refresh_token,
                        decoded = jwtDecode(response.tokens.token);
            
                    return of(
                        ...successActions('auth.alert.confirmRegistrationSuccess'),
                        loginWithToken(token, action.payload.panelAccessRole, refresh_token)
                    );
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError((error: any) => {
            return of(...errorActions(error));
        })
    );

const requestNewPasswordEpic: Epic = (action$) =>
    action$.pipe(
        ofType(requestNewPassword.type),
        switchMap((action: PayloadAction<IRequestNewPassword>) => {
            return createRequestResetPasswordAPI(action.payload.requestNewPasswordPayload).pipe(
                switchMap(() => {
                    const actions = successActions('auth.alert.sendResetPasswordMailSuccess');
                    return of(...actions);
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );

const setNewPasswordEpic: Epic = (action$) =>
    action$.pipe(
        ofType(setNewPassword.type),
        switchMap((action: PayloadAction<ISetNewPassword>) => {
            const newPassword: ISetNewPasswordInput = {
                password: action.payload.password,
            };
            return createSetNewPasswordAPI(action.payload.authToken, newPassword).pipe(
                switchMap(() => {
                    const actions = successActions('auth.alert.confirmResetPasswordSuccess');
                    return of(...actions);
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );

const errorActions = (error: IApiError) => {
    return [changeIsAuthPageLoading(false), addAlert(handleApiError(error))];
};

const successActions = (successMessage: string, isNotRegistered?: boolean) => {
    let redirectPath = '/';
    if (isNotRegistered) {
        redirectPath = '/auth/register';
    }
    return [
        changeIsAuthPageLoading(false),
        addAlert({message: successMessage, type: AlertType.SUCCESS, displayFor: 20 * 1000}),
        push(redirectPath),
    ];
};

const authEpic = combineEpics(
    registerUserEpic,
    confirmRegistrationEpic,
    requestNewPasswordEpic,
    setNewPasswordEpic,
);

export default authEpic;
