import { IModelApiObject } from './base';
import { IFileOutput, IPasswordStringInput, IModelCountry, ILocaleString } from './common';

export enum AuthType {
    LOGIN = 'login',
    REGISTER = 'register',
    NEW_PASSWORD = 'new_password',
    CHANGE_PASSWORD = 'change_password',
    RESET_PASSWORD = 'reset_password',
    ACCEPT_INVITATION = 'accept_invitation',
    CONFIRM_REGISTRATION = 'confirm_registration',
}

export enum UserRole {
    USER = 'ROLE_USER',
    EMPLOYEE = 'ROLE_EMPLOYEE',
    ADMIN = 'ROLE_ADMIN',
    SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
}

export enum UserRegistrationRole {
    EMPLOYEE = 'employee',
}

export interface IModelUser extends IModelApiObject {
    readonly id: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly phone: string | null;
    readonly birthDate: string | null;
    readonly cityName: string | null;
    readonly country: IModelCountry | null;
    readonly about: string | null;
    readonly avatar: IFileOutput | null;
    readonly locale: string;
}

export type ILoginInput = {
    username: string;
    password: string;
};
export type ILoginOutput = {
    readonly refresh_token: string;
    readonly token: string;
};

export type IConfirmRegistrationOutput = {
    readonly username: string;
    readonly tokens: ILoginOutput;
};
export type IChangeUserPasswordInput = {
    oldPassword: string;
    newPassword: string;
};

export type ISetNewPasswordInput = {
    password: IPasswordStringInput;
};

export type IRequestUserSetNewPasswordInput = {
    username: string;
    returnUrl: string;
};


export type IRegisterUserInput = {
    username: string;
    password: IPasswordStringInput;
    locale: ILocaleString;
    type: UserRegistrationRole;
    returnUrl: string;
};

export type IResendRegistrationConfirmationEmailInput = {
    username: string;
    returnUrl: string;
};
