import {combineEpics} from 'redux-observable';
import {ListSuccessActionsFunction} from '../../api/base/apiConnectionInfrastructure';
import {createFetchListEpic} from '../../utils/epicUtils';
import {addAlert} from '../reducers/alertSlice';
import {getSystemParametersAPI} from '../../api/systemParameters';
import {
    changeIsSystemParametersInitialized,
    changeIsSystemParametersLoading,
    changeSystemParameters,
    fetchSystemParameters,
    setSystemParametersError,
} from '../reducers/systemParametersSlice';
import {handleApiError} from '../../utils/errorHandlingUtils';
import {ISystemParameterOutput} from '../../model/systemPatameters';
import { AlertType } from '../../model/common';

const systemParametersSuccessActions: ListSuccessActionsFunction<ISystemParameterOutput> = (parameters: ISystemParameterOutput[]) => {
    return [changeSystemParameters(parameters), changeIsSystemParametersLoading(false), changeIsSystemParametersInitialized(true)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [changeIsSystemParametersLoading(false), setSystemParametersError(errorObj.message), addAlert(errorObj)];
};

const fetchSystemParametersEpic = createFetchListEpic<ISystemParameterOutput>(
    getSystemParametersAPI,
    systemParametersSuccessActions,
    errorActions,
    fetchSystemParameters().type
);

const systemParametersEpic = combineEpics(fetchSystemParametersEpic);

export default systemParametersEpic;
