import React, {useState} from 'react';
import {Field, useField, ErrorMessage} from 'formik';
import {FormikFieldConfig} from '../../../model/form';

const Password: React.FC<FormikFieldConfig> = ({name, label, className, isRequired, placeholder}) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [field, meta] = useField(name);

    const handleToggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={`password form-control ${className ? className : ''} ${meta.touched && meta.error ? 'invalid' : ''}`}>
            <Field
                type={showPassword ? 'text' : 'password'}
                {...field}
                placeholder={placeholder ? placeholder : ''}
                id={name}
                className={`form-input ${meta.touched && meta.error ? 'input-error' : ''}  ${field.value ? 'filled' : ''}`}
            />
            <label htmlFor={field.name} className="form-label">
                {label} {isRequired ? <span className="label-required">*</span> : ''}
            </label>
            <button type="button" onClick={handleToggleShowPassword} tabIndex={-1} className="action-button-wrapper">
                <div className={`action-button password-${showPassword ? 'visible' : 'hidden'}`}>
                    <span className="sr-only">{showPassword ? 'Hide password' : 'Show password'}</span>
                </div>
            </button>
            <ErrorMessage name={name} component="div" className="error-message" />
        </div>
    );
};

export default Password;
