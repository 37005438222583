import React from 'react';
import {FormikFieldConfig, FormikFieldTypes} from 'reservation-common-web';
import * as Yup from 'yup';
const personalDetailsFormControl = (
    t: (key: string) => string,
    customElement: React.ReactNode,
    showTermsAction: () => void
): FormikFieldConfig[] => {
    return [
        {
            name: 'guestName',
            label: t('reservationForm.personalDetails.form.labels.guestName'),
            type: FormikFieldTypes.TEXT,
            validation: Yup.date().required(t('validation.required')),
            className: '',
        },
        {
            name: 'phone',
            label: t('reservationForm.personalDetails.form.labels.phone'),
            type: FormikFieldTypes.TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'email',
            label: t('reservationForm.personalDetails.form.labels.email'),
            type: FormikFieldTypes.EMAIL,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'message',
            label: t('reservationForm.personalDetails.form.labels.message'),
            type: FormikFieldTypes.TEXTAREA,
            validation: Yup.string(),
            className: '',
        },
        {
            type: FormikFieldTypes.CUSTOM,
            label: '',
            validation: Yup.string(),
            name: 'reservation-fee',
            customElement: customElement,
        },
        {
            name: 'termsAndConditions',
            label: t(`reservationForm.personalDetails.form.labels.termsAndConditions`),
            type: FormikFieldTypes.SWITCH,
            isLabelWithLink: true,
            validation: Yup.string().required(t('validation.required')),
            className: '',
            linkAction: showTermsAction,
        },
    ];
};

export default personalDetailsFormControl;
