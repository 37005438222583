const en = {
    footer: {
        copyright: `Reservation Spot {{date}} © All right reserved`,
    },
    maintenance: {
        pageNotFound: 'Error - page not found',
    },
};

export default en;
