import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import { reservationsAPI } from '../provider/reservationsAPI';
import { IApiSingleResponseBase, IRawRestQueryParams } from '../../model/base';
import { IRestaurantOutput } from '../../model/restaurant';

export function getRestaurantDetailsAPI(
    authToken: string,
    payload?: {restaurantId: string},
    params?: IRawRestQueryParams | []
): Observable<IApiSingleResponseBase<IRestaurantOutput>> {
    return reservationsAPI.get(`api/restaurants/${payload.restaurantId}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
