import React from 'react';
import {ErrorMessage, useField} from 'formik';
import RichTextComponent from './RichTextComponent';

const RichTextField: React.FC<any> = ({name, label, required, ...props}) => {
    const [field, meta, helpers] = useField(name);

    return (
        <div className={`form-control rich-text ${meta.touched && meta.error ? 'invalid' : ''}`} key={name}>
            <label htmlFor={field.name} className="form-label">
                {label} {required ? <span className="label-required">*</span> : ''}
            </label>
            {/* PT: hack for not duplicating menu, when not in div it makes menu quadrupled */}
            <div className="rich-text-wrapper">
                <RichTextComponent value={field.value} onChange={(val: string) => helpers.setValue(val)} {...props} />
            </div>
            <ErrorMessage name={name} component="div" className="error-message" />
        </div>
    );
};

export default RichTextField;
