import {IModelDictionaryDatum} from './dictionaryDatum';
import {IModelApiObject} from './base';

export enum LoaderType {
    Local = 'local',
    Global = 'global',
}

export enum AlertType {
    INFO = 'info',
    WARNING = 'warning',
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum CurrencyType {
    EURO = 'EUR',
    DOLLAR = 'DOL',
    PLN = 'PLN',
}

export type ICanOutput = {
    readonly can: boolean;
};

export type ICountryOutput = {
    readonly id: string;
    readonly name: string;
};


export type ICurrencyOutput = {
    readonly name: string;
};

export type IMoneyOutput = {
    readonly amount: string;
    readonly currency: ICurrencyOutput;
};

export type IGeoPointOutput = {
    readonly longitude: number;
    readonly latitude: number;
};

export type IFileOutput = {
    readonly id: string;
    readonly fileName: string | null;
    readonly mimeType: string | null;
    readonly fileUrls: IFileUrlsOutput | null;
    readonly contentUrl: string | null;
};

export type IFileUrlsOutput = {
    readonly original: string;
    readonly tinyThumb: string;
    readonly smallThumb: string;
    readonly mediumThumb: string;
    readonly largeThumb: string;
};

export type IOpenHoursInput = {
    readonly _0?: IDayOpenHoursInput | null;
    readonly _1?: IDayOpenHoursInput | null;
    readonly _2?: IDayOpenHoursInput | null;
    readonly _3?: IDayOpenHoursInput | null;
    readonly _4?: IDayOpenHoursInput | null;
    readonly _5?: IDayOpenHoursInput | null;
    readonly _6?: IDayOpenHoursInput | null;
};

export interface IOpenHoursPayloadInput extends IOpenHoursInput  {
    timezone: string;
};

export type IDayOpenHoursInput = {
    readonly from: string;
    readonly to: string;
};

export type IPhoneOutput = {
    readonly country: string;
    readonly phone: string;
} & IModelApiObject;

export type IGeoPointInput = {
    latitude: number;
    longitude: number;
};

export type IPackageSizesInput = {
    s: boolean;
    m: boolean;
    l: boolean;
};

export type IPhoneInput = {
    country: string;
    phone: string;
};

export type IMoneyInput = {
    amount: string;
    currency: string;
};

export type ILocaleString = string;

export type IPasswordStringInput = string;

export interface IModelCity extends IModelDictionaryDatum {
    city: IModelDictionaryDatum;
}

export interface IModelCountry extends IModelDictionaryDatum {
    country: IModelDictionaryDatum;
}

export interface IModelColor extends IModelDictionaryDatum {
    color: IModelDictionaryDatum;
}

export type ITaxIdInput = {
    readonly taxIdentifier: string;
};

export type ISubscriptionDefinitionPurchaseInput = {};

export type ITranslationProps = {
    readonly text: string;
    readonly config?: {[key: string]: any};
    translationKey?: string;
};

export type IFileType = {
    name?: string;
    fileKey?: number | string;
    blobFile?: File;
    status?: 'inited' | 'uploading' | 'error' | 'finished';
    progress?: number;
    url?: string;
};

export type IImageUploaderProps = {
    readonly authToken: string;
    readonly triggerText: string;
    readonly className?: string;
    readonly onImageChange: (imageId: string, imageFile: IFileType) => void;
    readonly selectedImageFile?: any;
};

export type IAuthorizedImageProps = {
    url?: string | null;
    alt?: string;
    default?: string;
};