import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, mergeMap, switchMap } from 'rxjs/operators';
import { getCanDeleteAccountAPI } from '../../api/account/getCanDeleteAccountAPI';
import { updateLocaleAPI } from '../../api/account/changeLocaleAPI';
import { changeProfileAPI } from '../../api/account/changeProfileAPI';
import { deleteAccountAPI } from '../../api/account/deleteAccountAPI';
import { getAccountDataAPI } from '../../api/account/getAccountDataAPI';
import { createChangePasswordAPI } from '../../api/user/createChangePasswordAPI';
import { createConfirmEmailChangeAPI } from '../../api/user/createConfirmEmailChangeAPI';
import { IAccountBasicInfoOutput, IAccountMeOutput, IAccountProfileInput } from '../../model/account';
import { IChangeUserPasswordInput } from '../../model/user';
import { handleApiError } from '../../utils/errorHandlingUtils';
import { CommonRootState } from '../reducers';
import {
    changeLocale,
    changePassword,
    changePasswordSuccess, checkCanDeleteAccount,
    confirmEmailChange,
    deleteAccount,
    getFullAccount, IUpdateAccountProfile,
    IUpdateLocale,
    setAccountState,
    setCanDeleteAccount,
    updateAccountFailure,
    updateAccountInfoSuccess,
    updateAccountProfile
} from '../reducers/accountSlice';
import { IConfirmRegistration, logout } from '../reducers/authSlice';
import { setIsLoading } from '../reducers/loaderSlice';
import { initLogout } from '../reducers/loginSlice';
import { closeModal } from '../reducers/modalSlice';
import { accountIdSelector } from '../selectors/accountSelectors';
import { authTokenSelector } from '../selectors/authSelectors';
import { fetchAllDictionaryData } from '../reducers/countrySlice';
import { IApiError } from '../../model/auth';
import { IApiSingleResponseBase } from '../../model/base';
import { AlertType } from '../../model/common';
import { addAlert } from '../reducers/alertSlice';
import { changeIsPasswordChanged } from '../reducers/changePasswordSlice';

const errorActions = (error: IApiError) => {
    if ((error?.response?.['hydra:description'] as string)?.includes("oldPassword: This value should be the user's current password.")) {
        return [logout(true), setIsLoading(false), updateAccountFailure(error.message), addAlert(handleApiError('alerts.bad_password'))];
    }
    return [addAlert(handleApiError(error)), setIsLoading(false), updateAccountFailure(error.message)];
};

const getAccountBasicInfoEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(getFullAccount.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value) || '';
            return getAccountDataAPI(authToken).pipe(
                mergeMap((res: IApiSingleResponseBase<IAccountMeOutput>) => {
                    return of(setAccountState(res), setIsLoading(false));
                }),
                catchError(errorActions)
            );
        })
    );

const updateLocaleEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(changeLocale.type),
        switchMap((action: PayloadAction<IUpdateLocale>) => {
            const accountId = accountIdSelector(state$.value);
            const authToken = authTokenSelector(state$.value);
            return updateLocaleAPI(authToken, accountId, action.payload).pipe(
                mergeMap((res: IApiSingleResponseBase<IAccountBasicInfoOutput>) => {
                    return of(
                        addAlert({message: 'account_page.alerts.change_locale_success', type: AlertType.SUCCESS}),
                        updateAccountInfoSuccess(res),
                        fetchAllDictionaryData(),
                        setIsLoading(false)
                    );
                }),
                catchError(errorActions)
            );
        })
    );

const updatePasswordEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(changePassword.type),
        switchMap((action: PayloadAction<IChangeUserPasswordInput>) => {
            const authToken = authTokenSelector(state$.value) || '';
            const passwordPayload: IChangeUserPasswordInput = {
                oldPassword: action.payload.oldPassword,
                newPassword: action.payload.newPassword,
            };
            return createChangePasswordAPI(passwordPayload, authToken).pipe(
                mergeMap(() => {
                    return of(
                        addAlert({message: 'account_page.alerts.change_password_success', type: AlertType.SUCCESS}),
                        changePasswordSuccess(),
                        changeIsPasswordChanged(true),
                        setIsLoading(false)
                    );
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        })
    );

const updateAccountEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(updateAccountProfile.type),
        concatMap((action: PayloadAction<IUpdateAccountProfile>) => {
            const accountId = accountIdSelector(state$.value) || '',
                authToken = authTokenSelector(state$.value);

            const input: IAccountProfileInput = {
                username: action.payload.username,
                returnUrl: action.payload.returnUrl,
            };

            return changeProfileAPI(accountId, authToken, input).pipe(
                switchMap(() => getAccountDataAPI(authToken)),
                mergeMap((resp: IApiSingleResponseBase<IAccountMeOutput>) => {
                    return of(...basicOperationSuccessActions('account.alert.updateProfileDataSuccess'));
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError(errorActions)
    );

const deleteAccountEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(deleteAccount.type),
        switchMap(() => {
            const accountId = accountIdSelector(state$.value) || '',
                authToken = authTokenSelector(state$.value);
            return deleteAccountAPI(authToken, accountId).pipe(
                switchMap(() => {
                    return of(
                        setIsLoading(false),
                        closeModal(),
                        initLogout(),
                        addAlert({message: 'account_page.alerts.profile_deleted', type: AlertType.SUCCESS})
                    );
                })
            );
        }),
        catchError(errorActions)
    );

const canDeleteAccountEpic: Epic = (action$, state$: StateObservable<CommonRootState>) =>
    action$.pipe(
        ofType(checkCanDeleteAccount.type),
        switchMap(() => {
            const accountId = accountIdSelector(state$.value) || '',
                authToken = authTokenSelector(state$.value);
            return getCanDeleteAccountAPI(authToken, accountId).pipe(
                switchMap((resp) => {
                    return of(setCanDeleteAccount(resp.can), setIsLoading(false), closeModal());
                })
            );
        }),
        catchError(errorActions)
    );

const confirmEmailChangeEpic: Epic = (action$) =>
    action$.pipe(
        ofType(confirmEmailChange.type),
        switchMap((action: PayloadAction<IConfirmRegistration>) => {
            return createConfirmEmailChangeAPI(action.payload.registrationToken).pipe(
                switchMap(() => {
                    const actions = [setIsLoading(false)];
                    return of(...actions, addAlert({message: 'account_page.alerts.change_email_success', type: AlertType.SUCCESS}));
                }),
                catchError((error: any) => {
                    return of(...errorActions(error));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
const basicOperationSuccessActions = (successMessage: string) => [
    setIsLoading(false),
    addAlert({message: successMessage, type: AlertType.SUCCESS}),
    closeModal(),
    getFullAccount(),
];


const accountEpic = combineEpics(
    getAccountBasicInfoEpic,
    confirmEmailChangeEpic,
    canDeleteAccountEpic,
    updatePasswordEpic,
    updateLocaleEpic,
    deleteAccountEpic,
    updateAccountEpic
);

export default accountEpic;
