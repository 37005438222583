import React from 'react';
import {ErrorMessage, Field} from 'formik';
import {CustomFieldProps} from '../../../model/form';

export const TextareaField: React.FC<CustomFieldProps> = ({name, label, placeholder, disabled}) => (
    <div className={`form-control textarea ${placeholder ? 'placeholder-present' : ''}`}>
        <Field
            as="textarea"
            id={name}
            name={name}
            className="textarea-input"
            placeholder={placeholder ? placeholder : ''}
            disabled={disabled}
        />
        <label htmlFor={name} className="form-label">
            {label}
        </label>
        <ErrorMessage name={name} component="div" className="error-message" />
    </div>
);
